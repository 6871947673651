










import Vue from "vue";
export default Vue.extend({
	computed: {
		getClub() {
			return this.$store.state.club;
		},
		getImgPath(): string {
			if (this.getClub.imgPath)
				return this.$store.state.mainPath + this.getClub.imgPath;
			else
				return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		}
	}
});
