









import Vue from "vue";
import MemberBox from "../../Page/MemberBox.vue";
export default Vue.extend({
	components: {
		MemberBox
	},
	created() {
		this.$store
			.dispatch("GET_CLUB_MEMBERS")
			.then(members => {
				this.members = members;
			})
			.catch(err => {});
	},
	data() {
		return {
			members: []
		};
	}
});
