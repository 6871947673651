







import Vue from "vue";
export default Vue.extend({
	computed: {
		getClub() {
			return this.$store.state.club;
		}
	}
});
