








































































import Vue from "vue";
// @ts-ignore
import draggable from "vuedraggable";

import SwitchButton from "../../components/SwitchButton.vue";

import ClubTitleLayout from "../../components/Editor/Functional/ClubTitleLayout.vue";
import InformationLayout from "../../components/Editor/Functional/InformationLayout.vue";
import MembersLayout from "../../components/Editor/Functional/MembersLayout.vue";
import ApplyButtonLayout from "../../components/Editor/Functional/ApplyButtonLayout.vue";

export default Vue.extend({
	components: {
		SwitchButton,

		ClubTitleLayout,
		InformationLayout,
		MembersLayout,
		ApplyButtonLayout,
		draggable
	},
	data() {
		return {
			componentList: [] as any,
			drag: false
		};
	},
	created() {
		this.componentList = this.getClub.page || [];
	},
	methods: {
		onContextMenu(e: Event, idx: number) {
			e.preventDefault();
			this.componentList.splice(idx, 1);
		},
		componentAdd(e: MouseEvent, componentName: string) {
			this.componentList.push({
				component: componentName,
				data: {},
				id: new Date().getTime()
			});
		},
		commit() {
			this.$store.commit("pushLoading", {
				name: "CLUB_MODIFICATION",
				message: "동아리 사이트 수정 중"
			});
			this.$store
				.dispatch("CLUB_MODIFICATION", {
					page: this.componentList
				})
				.then(club => {
					this.$store.commit("clearLoading", "CLUB_MODIFICATION");
				})
				.catch(err => console.log(err));
		}
	},
	computed: {
		getClub(): any {
			return this.$store.state.club;
		},
		dragOptions() {
			return {
				animation: 400,
				disabled: false,
				group: "description",
				ghostClass: "ghost"
			};
		}
	}
});
